//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Mediaplayer from '../Partials/Mediaplayer/Mediaplayer.vue';

export default {
    name: 'VideoLarge',
    components: { Mediaplayer },
    props: ['data'],
    computed: {
        caption() {
            if (this.data?.video?.description) {
                return this.data?.video?.description;
            }

            return this.data?.caption;
        },
    },
};
